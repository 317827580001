
<!--
 * @Description: 房东计算账单详情
 * @Author: 琢磨先生
 * @Date: 2022-08-09 02:22:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-10-09 16:33:28
-->
<template>
  <el-drawer
    title="账单详情"
    v-model="visibleDrawer"
    size="1200px"
    @closed="onClosed"
    :destroy-on-close="true"
  >
    <div style="height:100%;" v-loading="loading">
      <dtl-page :model="model" @reload="loadData"></dtl-page>
    </div>
    <template #footer>
      <el-button
        type="info"
        @click="onRollBack"
        :loading="is_rollBack"
        :disabled="saving"
        v-if="model.status == 20 || model.status == 30"
        v-power="'seller/v1/landlordbill/rollback'"
        >回滚状态</el-button
      >
      <el-button
        type="primary"
        :loading="saving"
        v-if="model.status == 20 || model.status == 30"
        v-power="'seller/v1/landlordBill/settled'"
        @click="onSubmitSettled"
      >已结算</el-button>
    </template>
  </el-drawer>
</template>

<script>
import DtlPage from "./dtl_page.vue";
export default {
  components: {
    DtlPage,
  },
  data() {
    return {
      saving: false,
      loading: false,
      visibleDrawer: false,
      is_rollBack:false,
      //详情对象
      model: {},
      //收益表数据
      incomeTable: [],
    };
  },
  emits: ["close", "success"],
  props: ["id", "from"],
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visibleDrawer = true;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    /**
     * 加载详情
     */
    loadData() {
      this.loading = true;
      this.$http
        .get("seller/v1/LandlordBill/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            var model = res.data;
            this.model = res.data;
            this.incomeTable = [
              {
                type: 0,
                name: "商户",
                income_amount: `￥${model.decimal_business_income_amount}`,
                expense_amount: `￥${model.decimal_business_expense_amount}`,
                subsidy_amount: "-",
                actual_expense_amount: `￥${model.decimal_business_actual_expense_amount}`,
                profit_amount: `￥${model.decimal_business_profit_amount}`,
              },
              {
                type: 1,
                name: "业主",
                income_amount: `￥${model.decimal_landlord_income_amount}(${model.decimal_income_rate}%)`,
                expense_amount: `￥${model.decimal_landlord_expense_amount}`,
                subsidy_amount: `￥${model.decimal_landlord_subsidy_amount}`,
                actual_expense_amount: `￥${model.decimal_landlord_expense_amount}`,
                profit_amount: `￥${model.decimal_landlord_profit_amount}`,
              },
            ];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onClosed() {
      this.$emit("close");
    },

    /**
     * 提交账单已经结算
     */
    onSubmitSettled() {
      this.$confirm("是否已经结算？", "温馨提示").then(() => {
        this.saving = true;
        this.$http
          .get("seller/v1/landlordBill/settled?id=" + this.model.id)
          .then((res) => {
            if (res.code == 0) {
              this.loadData();
              this.$emit("success");
            }
          })
          .finally(() => (this.saving = false));
      });
    },

    /**
     * 回滚，更改订单状态，回滚到校对中(只有待确认、或者已确认状态才可以)
     */
    onRollBack(){
      this.$confirm("是否将账单状态改为【校对中】？", "温馨提示").then(() => {
        this.is_rollBack = true;
        this.$http
          .get("seller/v1/landlordBill/rollback?id=" + this.model.id)
          .then((res) => {
            if (res.code == 0) {
              this.loadData();
              this.$emit("success");
            }
          })
          .finally(() => (this.is_rollBack = false));
      });
    }
  },
};
</script>
<!-- 
<style   scoped>
.box {
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* 房号 */
.box .house_no {
  padding: 10px 0;
  font-weight: bold;
  font-size: 18px;
}

.box .data {
  flex-grow: 1;
  margin-top: 20px;
}

.el-tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
</style>

<style>
.bill_custom_class .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bill_custom_class .el-tabs__content {
  height: 1px;
  flex-grow: 1;
}
.bill_custom_class .el-tab-pane {
  height: 100%;
}
.bill_custom_class .data .el-table {
  height: 100%;
}
</style> -->