<!--
 * @Description: 房源订单结算数据
 * @Author: 琢磨先生
 * @Date: 2022-08-09 02:22:56
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2024-07-05 10:28:33
-->

<template>
  <el-card class="box">
    <el-form :model="query" ref="query" :inline="true">
      <el-form-item label="房间">
        <el-cascader
          :options="huxing_list"
          v-model="query.storeIds"
          clearable
          :props="{ multiple: true }"
          collapse-tags
          collapse-tags-tooltip
          filterable
        ></el-cascader>
      </el-form-item>
      <el-form-item label="分成比例">
        <el-select v-model="query.rule_id" placeholder clearable style="width:150px;">
          <el-option v-for="item in incomerules" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="状态">
        <el-select v-model="query.status" placeholder clearable style="width: 140px">
          <el-option
            v-for="item in enums.landlord_bill_status"
            :key="item.value"
            :value="item.value"
            :label="item.text"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="月份">
        <el-date-picker v-model="query.dates" type="monthrange" unlink-panels format="YYYY年MM月" value-format="YYYY-MM-01"  placeholder></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
      </el-form-item>
      <el-form-item label>
        <el-tooltip class="box-item" effect="dark" content="下载查询结果数据" placement="top">
          <el-button icon="Download" @click="downloadExcel" :loading="downloading">下载数据</el-button>
        </el-tooltip>
      </el-form-item>
    </el-form>
    <!-- <bill-create @close="onSearch" :huxing_list="huxing_list"></bill-create> -->
    <el-button
      type="success"
      size="small"
      round
      icon="setting"
      v-power="'seller/v1/landlordBill/settled/batch'"
      @click="batchSetSettled"
    >批量设置已结算</el-button>
  </el-card>
  <el-card class="box data" v-loading="loading">
    <div class="total_wrp">
      <span>总额：￥{{ totalModel.total_amount }}</span>
      <span>成本：￥{{ totalModel.expense_amount }}</span>
      <span>实际成本：￥{{ totalModel.actual_expense_amount }}</span>
      <span>业主分成：￥{{ totalModel.landlord_amount }}</span>
      <span>业主补扣：￥{{ totalModel.landlord_subsidy_amount }}</span>
      <span>业主净利润：￥{{ totalModel.landlord_profit_amount }}</span>
      <span>商户分成：￥{{ totalModel.business_amount }}</span>
      <span>商户实际：￥{{ totalModel.business_actual_expense_amount }}</span>
      <span>商户利润：￥{{ totalModel.business_profit_amount }}</span>
    </div>
    <el-table :data="tableData.data" border row-key="id" @selection-change="selectionChange">
      <!-- <el-table-column label="编号" prop="id" width="80"></el-table-column> -->
      <el-table-column label type="selection" reserve-selection :selectable="selectableHandle"></el-table-column>
      <el-table-column label="账期" width="120">
        <template #default="scope">
          <div>{{ scope.row.month_text }}</div>
        </template>
      </el-table-column>
      <el-table-column label="房东" prop="landlord.name" width="140">
        <!-- <template #default="scope">
          <div>{{ scope.row.landlord.name }}</div>
        </template>-->
      </el-table-column>
      <el-table-column label="房间" width="200" prop="house_no"></el-table-column>
      <el-table-column label="营业总额" width="140">
        <template #default="scope">￥{{ scope.row.decimal_total_amount }}</template>
      </el-table-column>
      <el-table-column label="业主分成" width="140">
        <template #default="scope">
          <div>{{ scope.row.decimal_income_rate }}%</div>
          <div>￥{{ scope.row.decimal_landlord_income_amount }}</div>
        </template>
      </el-table-column>
      <el-table-column label="成本" width="120">
        <template #default="scope">￥{{ scope.row.decimal_expense_total_amount }}</template>
      </el-table-column>
      <el-table-column label="实际成本" width="120">
        <template #default="scope">￥{{ scope.row.decimal_actual_expense_total_amount }}</template>
      </el-table-column>
      <el-table-column label="业主成本" width="120">
        <template #default="scope">￥{{ scope.row.decimal_landlord_expense_amount }}</template>
      </el-table-column>
      <el-table-column label="业主补扣" width="120">
        <template #default="scope">￥{{ scope.row.decimal_landlord_subsidy_amount }}</template>
      </el-table-column>
      <el-table-column label="业主结余转入" width="120">
        <template #default="scope">￥{{ scope.row.decimal_landlord_in_amount }}</template>
      </el-table-column>
      <el-table-column label="业主结余转出" width="120">
        <template #default="scope">￥{{ scope.row.decimal_landlord_out_amount }}</template>
      </el-table-column>
      <el-table-column label="业主净收益" width="120">
        <template #default="scope">￥{{ scope.row.decimal_landlord_profit_amount }}</template>
      </el-table-column>
      <el-table-column label="商户成本" width="120">
        <template #default="scope">￥{{ scope.row.decimal_business_expense_amount }}</template>
      </el-table-column>
      <el-table-column label="商户实际成本" width="120">
        <template #default="scope">￥{{ scope.row.decimal_business_actual_expense_amount }}</template>
      </el-table-column>
      <el-table-column label="商户净收益" width="120">
        <template #default="scope">￥{{ scope.row.decimal_business_profit_amount }}</template>
      </el-table-column>
      <el-table-column label="状态" width="120">
        <template #default="scope">
          <el-tag size="small" type="success" v-if="scope.row.status == 40">
            {{
            scope.row.status_text
            }}
          </el-tag>
          <el-tag
            size="small"
            type="danger"
            v-else-if="scope.row.status == 30"
          >{{ scope.row.status_text }}</el-tag>
          <el-tag
            size="small"
            type="warning"
            v-else-if="scope.row.status == 20"
          >{{ scope.row.status_text }}</el-tag>
          <el-tag size="small" v-else-if="scope.row.status == 10">
            {{
            scope.row.status_text
            }}
          </el-tag>
          <el-tag size="small" type="info" v-else>
            {{
            scope.row.status_text
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="业主不可见" width="120">
        <template #default="scope">
          <span v-if="[20,30,40].find(o=>scope.row.status)">
            <el-link
              :underline="false"
              size="small"
              type="success"
              v-if="!scope.row.is_owner_invisible"
            >可见</el-link>
            <el-link size="small" type="danger" v-else>不可见</el-link>
          </span>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column>
      <el-table-column label="操作" fixed="right" width="160">
        <template #default="scope">
          <el-button type="primary" link @click="onDtl(scope.row)" v-if="is_can_dtl">详情</el-button>
          <el-popconfirm
            :title="`是否更改可见状态为【${scope.row.is_owner_invisible?'可见':'不可见'}】？`"
            @confirm="onInvisible(scope.row)"
            v-if="[20,30,40].find(o=>scope.row.status) && is_can_dtl"
          >
            <template #reference>
              <el-button type="info" link size="small" class="text-danger">可见设置</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <bill-dtl :id="current_id" @close="current_id = null" @success="loadData"></bill-dtl>
</template>

<script>
// import BillCreate from "./create.vue";
import BillDtl from "./dtl.vue";
import common_api from "@/http/common_api";
import huxing_api from "@/http/huxing_api";
import fileDownload from "js-file-download";

export default {
  components: {
    BillDtl,
    // BillCreate,
  },
  data() {
    return {
      loading: false,
      downloading: false,
      //数据
      tableData: {
        counts: 0,
      },
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
      //
      enums: {},
      //房型options
      huxing_list: [],
      //当前id
      current_id: "",
      //统计数据
      totalModel: {},

      //分成比例
      incomerules: [],
      //可以查看详情
      is_can_dtl: false,
    };
  },
  created() {
    common_api.get_enums().then((res) => {
      if (res.code == 0) {
        this.enums = res.data;
      }
    });
    common_api.get_incomerules().then((res) => {
      if (res.code == 0) {
        this.incomerules = res.data;
      }
    });
    huxing_api.get_stores().then((res) => {
      if (res.code == 0) {
        this.huxing_list = [];
        res.data.forEach((x) => {
          var model = {
            value: x.id,
            label: x.name,
            children: [],
          };
          x.store_list.forEach((item) => {
            model.children.push({
              value: item.id,
              label: item.house_no,
            });
          });
          this.huxing_list.push(model);
        });
      }
    });
    this.loadData();
    this.getTotal();

    this.is_can_dtl = this.$power("seller/v1/landlordbill/dtl");
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
      this.getTotal();
    },

    /**
     * 加载数据
     */
    loadData() {
      var arg = Object.assign({}, this.query);
      delete arg.storeIds;
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.loading = true;
      this.$http.post("seller/v1/landlordBill", arg).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 获取统计数据
     */
    getTotal() {
      var arg = Object.assign({}, this.query);
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.$http.post("seller/v1/landlordBill/total", arg).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.totalModel = res.data;
        }
      });
    },

    /**
     * 选择更改
     */
    selectionChange(nodes) {
      this.selection_list = nodes;
    },

    /**
     * 是否允许选择
     * @param {*} row
     */
    selectableHandle(row) {
      // console.log(row);
      return row.status == 20 || row.status == 30;
    },

    /**
     * 提交批量设置已经结算
     */
    batchSetSettled() {
      if (this.selection_list && this.selection_list.length > 0) {
        //
        this.$confirm("是否确定设置选择的数据为已结算？", "温馨提示").then(
          () => {
            this.$http
              .post(
                "seller/v1/landlordBill/settled/batch",
                this.selection_list.map((x) => x.id)
              )
              .then((res) => {
                if (res.code == 0) {
                  this.$message.success(res.msg);
                  this.loadData();
                }
              });
          }
        );
      } else {
        this.$message.error("请选择数据");
      }
    },
    /**
     * 查看详情
     */
    onDtl(item) {
      this.current_id = item.id;
    },

    /**
     * 下载数据
     */
    downloadExcel() {
      this.downloading = true;
      var arg = Object.assign({}, this.query);
      if (
        Array.isArray(this.query.storeIds) &&
        this.query.storeIds.length > 0
      ) {
        arg.store_ids = this.query.storeIds.map((x) => {
          return x[1];
        });
      }
      this.$http
        .post("seller/v1/landlordBill/export", arg, {
          responseType: "blob",
        })
        .then((res) => {
          fileDownload(res, "账单导出.xlsx");
        })
        .finally(() => {
          this.downloading = false;
        });
    },

    /**
     * 设置可见状态
     * @param {*} item
     */
    onInvisible(item) {
      this.$http
        .get(
          `seller/v1/landlordBill/invisible?id=${
            item.id
          }&status=${!item.is_owner_invisible}`
        )
        .then((res) => {
          if (res.code == 0) {
            this.$message.success(res.msg);
            this.loadData();
          }
        });
    },
  },
};
</script>

<style   scoped>
.total_wrp {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.total_wrp span {
  margin-right: 40px;
}
</style>